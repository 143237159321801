// Primary color for text, backgrounds, etc.
$primary-color: #fff;

// Secondary color for text, backgrounds, etc.
$secondary-color: #181818;

// Default top margin/padding
$tops: 10px;

// Responsive line height for horizontal elements
$linesH: 30px;

// Responsive line height for vertical elements
$linesV: 24px;

// Responsive line height for bottom elements
$lineB: 45px;

$lineG: 72px;

// Responsive width for lines or elements
$lineW: 15px;

// Responsive radius for rounded elements
$lineR: 5px;

$linesM: 40px;


// Import variables for consistent styling
@import "variables";

// Navbar styling
.navbar {
  background: transparent; // Make the navbar background transparent
  width: 100%; // Full width of the viewport
  top: 0; // Stick to the top of the viewport
}

// Navbar link styling
.navbar-nav .nav-link {
  text-align: center; // Center align text in navbar links
  color: #fff; // White color for text
  font-size: 16px;
}

// Logo image styling
.logo-img {
  width: 100%; // Make logo image occupy full width of its container
}

.contact {
  display: flex;
  gap: 10px;
  padding-right: 50px;
  .nav-item {
    .nav-link {
      font-size: 20px;
    }
  }
}

// Icon image styling
.icon-img {
  max-width: 100px; // Set maximum width for icon images
  margin-left: 10px; // Add left margin for spacing between icons
}

// Navbar item spacing
.navbar-nav .nav-item {
  margin: 0 10px; // Horizontal spacing between navbar items
}

.legit-logo {
  width: 25%;
}
.storebtn {
  display: flex;
  a {
    margin: 10px 0px;
    width: 150px;
    height: 60px; /* Ensure both buttons have the same height */
    display: flex;
    align-items: center;
    justify-content: center;
    border: none; /* Example border */
    border-radius: 8px; /* Example border radius */
    text-decoration: none; /* Remove underline from links */
    transition: all 0.3s ease-out; /* Smooth transition for hover effects */
  }
  @media (max-width: 998px) {
    flex-direction: column;
  }
  .googla {
    width: 100%;
  }
  .appla {
    width: 82%;
  }
}
// Navbar toggle button styling
.navbar-toggler {
  border: none; // Remove border from the toggle button
  outline: none; // Remove outline from the toggle button
}

.nav-item {
  list-style-type: none;
}

// Navbar item spacing when expanded
.navbar-expand-lg .navbar-nav {
  gap: 10px; // Gap between navbar items
}

// Responsive design for smaller screens
@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column; // Stack navbar items vertically on smaller screens
    align-items: flex-start; // Align items to the left on smaller screens
  }

  .icon-img {
    margin-left: 0; // Remove left margin
    // margin-bottom: 10px; // Add bottom margin for spacing between icons
  }

  .downdrop {
    display: none;
  }
  .legit-logo {
    width: 50%;
  }

  .contact {
    flex-direction: column; // Keep icons horizontally aligned
    justify-content: flex-end !important;
    gap: 10px; // Space between icons
  }

  .nav-item {
    margin: 10px 0; // Vertical spacing between navbar items
  }

  .navbar-collapse {
    justify-content: flex-start; // Align navbar collapse content to the left
  }
  .navbar-nav .nav-link {
    font-size: 10px;
  }

  .navbar-nav {
    --bs-nav-link-padding-y: 0.1rem !important;
  }

  // Remove margin for icons in responsive view
  .icon-img {
    margin: 0; // No margin
  }

  // Adjust spacing between navbar items in responsive view
  .navbar-nav {
    gap: 20px; // Increase gap between items
  }
}
